<template>
  <div class="page">
    <div class="page-header">
      <div>
        <a-input
          allowClear
          placeholder="关键词"
          v-model="params.keyword"
          @pressEnter="search"
          size="small"
          style="margin: 5px; width: 180px"
        />
        <!-- 对版状态 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          size="small"
          style="width: 120px; margin: 5px"
          v-model="params.matchingQueryStatus"
          placeholder="机器对版"
        >
          <a-select-option :value="0">未对版</a-select-option>
          <a-select-option :value="1">已对版</a-select-option>
        </a-select>
        <!-- 标注状态 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          size="small"
          style="width: 120px; margin: 5px"
          v-model="params.companyType"
          placeholder="来源渠道"
        >
          <a-select-option :value="1">GB</a-select-option>
          <a-select-option :value="2">BC</a-select-option>
          <a-select-option :value="3">WPT</a-select-option>
        </a-select>
        <a-button
          @click="search"
          size="small"
          style="margin-left: 10px"
        >搜索</a-button>

        <a-popconfirm
          title="确认操作?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="batchSend()"
          :disabled="isDisabled"
        >
          <a-button
            :disabled="isDisabled"
            size="small"
            style="margin-left: 10px"
          >对版</a-button>
        </a-popconfirm>
      </div>
    </div>
    <div class="page-body">
      <PageTable
        :list="list"
        :loading="loading"
        :pagination="pagination"
        @selectChange="onSelectChange"
        @changePage="changePage"
        @success="getList"
        @deleteItem="deleteItem"
        ref="table"
      ></PageTable>
    </div>
  </div>
</template>

<script>
import PageTable from "@/views/cmsPage/versionManage/preGBBCMark/PageTable.vue";
export default {
  data() {
    return {
      selctedArrays: [],
      list: [],
      loading: false,
      detail: "",
      params: {
        pageNum: 1,
        pageSize: 20,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      screenList: [],
      versionList: [],
    };
  },
  computed: {
    isDisabled() {
      if (this.selctedArrays.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: {
    PageTable,
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 删除
    deleteItem(id) {
      const index = this.list.findIndex((el) => {
        return el.spliderId == id;
      });
      this.list.splice(index, 1);
    },
    // 批量推送
    async batchSend() {
      let ids = this.selctedArrays.map((row) => row.id);
      ids = ids.join(",");
      this.loading = true;
      const res = await this.axios(
        "/dq_admin/duibanUserRecord/versionMatchingQuery",
        {
          params: { ratingSpliderIds: ids },
        }
      );
      this.loading = false;
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.getList();
    },
    // 获取table选中数据
    onSelectChange(selctedArrays) {
      this.selctedArrays = JSON.parse(JSON.stringify(selctedArrays));
    },
    // 分页
    changePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },

    // 数据列表
    async getList() {
      this.loading = true;
      const res = await this.axios("/dq_admin/ratingSplider/waitBbOrBclist", {
        params: this.params,
      });
      this.loading = false;
      if (res.status != 200) return;
      this.pagination.total = res.data.total;
      this.list = res.data.records;
    },
    // 点击搜索按钮
    search() {
      this.pagination.current = 1;
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
.page-header {
  display: flex;
}
</style>