<template>
  <!-- 推送样本库 -->
  <a-modal
    v-model="pushModal"
    :width='1500'
    title="修改正反图"
    ok-text="确认"
    cancel-text="取消"
    @cancel="cancelPushModal"
    @ok="confirmPushModal"
  >
    <div class="push-modal">
      <div class="modal-title">正面图</div>
      <div class="modal-image">
        <div
          class="item"
          v-for="(item, index) in images"
          :key="index"
          :class="{current: selected_1 == index}"
          @click.stop="selected_1 = index"
        >
          <div class="image-box">
            <img :src="item" />
          </div>
            <div class="btn">{{selected_1 == index ? "已选择" : "选择"}}</div>
        </div>
      </div>
      <div class="modal-title">反面图</div>
      <div class="modal-image">
        <div
          class="item"
          v-for="(item, index) in images"
          :key="index"
          :class="{current: selected_2 == index}"
          @click.stop="selected_2 = index"
        >
          <div class="image-box">
            <img :src="item" />
          </div>
          <div class="btn">{{selected_2 == index ? "已选择" : "选择"}}</div>
        </div>
      </div>
    </div>

  </a-modal>
</template>

<script>

export default {
  data() {
    return {
      pushModal: false,
      curItem: {},
      images: ["", "", "", "", ""],
      selected_1: 0,
      selected_2: 1,
    };
  },
  methods: {
    show(data) {
      this.curItem = JSON.parse(JSON.stringify(data))
      this.images = this.curItem.covers
      this.selected_1 = 0,
      this.selected_2 = 1,
      this.pushModal = true;
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal() {
      this.$loading.show();
      const res = await this.axios("/dq_admin/ratingSplider/editFrontAndBackImage", {
        params: { ratingSpliderId: this.curItem.id,
        frontIndex: this.selected_1,
        backIndex: this.selected_2},
      });
      this.$loading.hide();
      if (res.status != 200) return
      this.cancelPushModal()
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
  text-align: center;
}
.modal-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 8px;
    border: solid 4px transparent;
    cursor: pointer;
    .image-box {
      overflow: hidden;
      width: 200px;
      height: 200px;
      position: relative;
      img {
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .btn{
      width: 100px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: solid 1px black;
      margin-top: 8px;
    }
  }
  .item.current{
    border: solid 4px #1890ff;
    .btn{
      background: #1890ff;
      color: #FFFFFF;
      border-color: #1890ff;
    }
  }
}
</style>