<template>
  <div>
    <a-table
      @change="changePage"
      :pagination="pagination"
      :loading="loading"
      :columns="columns"
      :data-source="list"
      :scroll="{ x: 800 }"
      :rowKey="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <div
        slot="image_1"
        slot-scope="row"
        class="image"
        @click="editImage(row)"
      >
        <img
          style="max-width: 100px; max-height: 200px"
          :src="row.covers[0]"
        />
      </div>
      <div
        slot="image_2"
        slot-scope="row"
        class="image"
        @click="editImage(row)"
      >
        <img
          style="max-width: 100px; max-height: 200px"
          :src="row.covers[1]"
        />
      </div>
      <div
        slot="setup"
        slot-scope="row"
        style="display: flex; flex-direction: column"
      >

        <div style="margin-top: 5px; display: flex;justify-content: center;align-items: center;">
          <a-popconfirm
            title="是否确认?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="confirm(row.id)"
            :disabled="row.markType == 1"
          >
            <a-button
              size="small"
              type="primary"
              style="margin-right: 5px"
              :disabled="row.markType == 1"
            >推送待标注</a-button>
          </a-popconfirm>

          <a-button
            size="small"
            type="primary"
            style="margin-right: 5px"
            :disabled="row.markType == 1"
            @click="pushSampleClick(row)"
          >推送到底库</a-button>
          <!-- <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteItem(row.id)"
          >
            <a-button
              size="small"
              type="danger"
            >删除</a-button>
          </a-popconfirm> -->
        </div>
      </div>
    </a-table>

    <!-- 推送样本库 -->
    <a-modal
      v-model="pushModal"
      :width='1300'
      title="推送到样本库"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelPushModal"
      @ok="confirmPushModal"
    >
      <div class="push-modal">
        <div class="lately-search" v-if="latelySearchBtnList && latelySearchBtnList.length">
          <span>最近搜索：</span>
          <a-popconfirm
              v-for="item in latelySearchBtnList"
              :key="item.coinKindId"
              title="确定是为当前币种？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleLatelySearch(item)"
          >
            <a-button
            >{{ item.value }}</a-button>
          </a-popconfirm>
        </div>
        <div>
          <!-- 选择大币种 -->
          <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoin"
            @search="coinSearch"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="curItem.coinKindId"
            placeholder="请选择大币种"
          >
            <a-select-option
              v-for="item of coinList"
              :key="item.id"
              :value="item.id"
            >{{ item.coinKindName }}</a-select-option>
          </a-select>
          <!-- 选择小币种 -->
          <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoinItem"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="curItem.coinKindItemId"
            placeholder="请选择小币种"
          >
            <a-select-option
              v-for="item of coinItemList"
              :key="item.id"
              :value="item.id"
            >{{ item.coinKindItemName }}</a-select-option>
          </a-select>
          <!-- 选择版别 -->
          <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            @change="changeVersion"
            showSearch
            :filter-option="untils.filterOption"
            size="small"
            style="margin: 5px; width: 180px"
            v-model="curItem.coinKindItemVersionId"
            :dropdownMenuStyle="{'max-height': '500px'}"
            placeholder="版别"
          >
            <a-select-option
              v-for="item of versionList"
              :key="item.id"
              :value="item.id"
            >{{ `${item.coinKindVersionName}` }}</a-select-option>
          </a-select>
        </div>
        <div class="images">
          <div
            class="image-item"
            v-for="(item, index) in curItem.covers"
            :key="index"
          >
            <img
              class="image"
              :src="item"
            />
            <a-popconfirm
              title="Are you sure delete this item?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="deleteImage(index)"
            >
              <a-button
                size="small"
                type="danger"
              >删除</a-button>
            </a-popconfirm>
          </div>

        </div>
      </div>

    </a-modal>

    <ModalEditImage
      ref="edit-image"
      @success="editSuccess"
    ></ModalEditImage>
  </div>
</template>

<script>
import ModalEditImage from "@/views/cmsPage/versionManage/preGBBCMark/ModalEditImage.vue";

export default {
  props: ["list", "loading", "pagination"],
  data() {
    return {
      pushModal: false,
      curItem: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
      selectedRowKeys: [],
      columns: [
        { title: "id", dataIndex: "id", width: 100 },
        { title: "币种名称", dataIndex: "itemCoinName", width: 180 },
        { title: "小币种", dataIndex: "itemCoinItemName", width: 180 },
        { title: "版别", dataIndex: "itemVersionName", width: 180 },
        {
          title: "来源渠道",
          dataIndex: "itemCompanyType",
          width: 120,
          customRender: (text, row, index) => {
            switch (row.itemCompanyType) {
              case 1:
                return "公博";
              case 2:
                return "保粹";
              case 3:
                return "WPT";
            }
          },
        },
        { title: "正面图", scopedSlots: { customRender: "image_1" }, width:  200},
        { title: "反面图", scopedSlots: { customRender: "image_2" }, width: 200 },
        {
          title: "机器对版",
          dataIndex: "matchingQueryStatus",
          width: 120,
          customRender: (text, row, index) => {
            switch (row.matchingQueryStatus) {
              case 0:
                return "未对版";
              case 1:
                return "已对版";
              default:
                return "未对版";
            }
          },
        },
        {
          title: "推送状态",
          dataIndex: "markType",
          width: 120,
          customRender: (text, row, index) => {
            switch (row.markType) {
              case 0:
                return "未推送";
              case 1:
                return "已推送";
              default:
                return "未推送";
            }
          },
        },
        {
          title: "操作",
          align: "center",
          // fixed: 'right',
          scopedSlots: { customRender: "setup" },
          width: 200,
        },
      ],
      latelySearchBtnList: [],
      tempCoinItemId: '',
      tempCoinVersionId: ''
    };
  },
  watch: {
    loading() {
      // this.selectedRowKeys = [];
      // this.$emit("selectChange", []);
    },
  },
  components: {
    ModalEditImage,
  },
  methods: {
    editSuccess() {
      this.$emit("success");
    },
    editImage(row) {
      this.$refs["edit-image"].show(row);
    },
    // 删除图片
    deleteImage(index) {
      this.curItem.covers.splice(index, 1);
    },

    // 搜索，大币种搜索
    async coinSearch(str) {
      if (!str) return;
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: str },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;
    },

    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.curItem.coinSid },
      });
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal() {
      const {
        id,
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
        covers,
      } = this.curItem;
      const coverImages = [covers.join(",")];
      const res = await this.axios.post(
        "/dq_admin/ratingSplider/pushAsynDiku",
        {
          type: 1, // bc/gb推送
          id,
          coinKindId,
          coinKindName,
          coinKindItemId,
          coinKindItemName,
          coinKindItemVersionId,
          coinKindItemVersionName,
          coverImages,
        }
      );
      if (res.status != 200) return;
      const findIndex = this.latelySearchBtnList.findIndex(el => el.id === coinKindId)
      if (findIndex < 0) this.latelySearchBtnList.unshift({
        coinKindId: coinKindId,
        coinKindName: coinKindName,
        itemCoinName: coinKindName,
        coinKindItemName,
        coinKindItemVersionName,
        coinKindItemId: coinKindItemId,
        coinKindItemVersionId: coinKindItemVersionId,
        value: coinKindName + ' ' + coinKindItemName + '·' + coinKindItemVersionName,
      })
      if (this.latelySearchBtnList && this.latelySearchBtnList.length > 10) {
        this.latelySearchBtnList.splice(10)
      }
      localStorage.setItem('lately-search-quick-sure', JSON.stringify(this.latelySearchBtnList))
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
    /** 点击最近搜索 */
    async handleLatelySearch(item) {
      Object.assign(this.curItem, item)
      await this.confirmPushModal()
    },
    // 推送到样本库点击
    async pushSampleClick(item) {
      this.pushModal = true
      this.latelySearchBtnList = JSON.parse(localStorage.getItem('lately-search-quick-sure')) || []
      this.curItem = JSON.parse(JSON.stringify(item));
      await this.setDefault();
      await this.coinItemSearch();
    },
    // 默认值
    async setDefault() {
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: this.curItem.itemCoinName },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;
      const foundData = this.coinList.find(el => el.coinKindName === this.curItem.itemCoinName)
      this.curItem.coinKindId = foundData.id;
      this.curItem.coinSid = foundData.sid;
      this.curItem.coinKindName = foundData.coinKindName;
    },

    // 大币种改变
    changeCoin(val) {
      delete this.curItem.coinKindItemId;
      delete this.curItem.coinKindItemName;
      delete this.curItem.versionId;
      delete this.curItem.coinKindItemVersionName;
      if (!val) return;
      const coin = this.coinList.find((el) => {
        return el.id == this.curItem.coinKindId;
      });
      this.curItem.coinSid = coin.sid;
      this.curItem.coinKindName = coin.coinKindName;

      this.coinItemSearch();
    },
    //  选择小币种
    changeCoinItem(val) {
      delete this.curItem.versionId;
      delete this.curItem.coinKindItemVersionName;
      if (!val) return;
      const coinItem = this.coinItemList.find((el) => {
        return el.id == this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      this.versionList = coinItem.coinItemVersionList;
    },
    // 版别改变
    changeVersion(val) {
      if (!val) return;
      const version = this.versionList.find((el) => {
        return el.id == this.curItem.coinKindItemVersionId;
      });
      this.curItem.coinKindItemVersionName = version.coinKindVersionName;
    },

    // 多选
    onSelectChange(keys, arr) {
      this.selectedRowKeys = keys;
      this.$emit("selectChange", arr);
    },
    // 标注
    async confirm(id) {
      this.$loading.show();
      const res = await this.axios("/dq_admin/ratingSplider/pushAsyncMark", {
        params: { id: id + "" },
      });
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
    // 删除
    async deleteItem(id) {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/quanhaiVersionDataSplider/deleteById",
        {
          params: {
            id,
          },
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.$emit("deleteItem", id);
    },
    // 切换分页
    changePage(page) {
      this.selectedRowKeys = [];
      this.$emit("selectChange", []);
      this.$emit("changePage", page);
    },
    // 预览封面
    previewImg(row) {
      const images = row.covers.map((el) => {
        return {
          img_url: el,
        };
      });
      this.$previewImg({
        list: images,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-content {
  max-width: 600px;
}

.push-modal {
  .images {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .image-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 10px 10px 0;
      .image {
        min-width: 200px;
        height: 200px;
        margin-bottom: 10px;
        background: #f2f2f2;
        border-radius: 4px;
      }
    }
  }
}
.image {
  min-width: 50px;
  min-height: 50px;
}
.lately-search {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  button {
    margin-right: 15px;
    margin-bottom: 20px;
  }
}
</style>